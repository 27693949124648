<template>
  <card class="aws-panel" headerClasses="aws-header">
    <template slot="header">
      <div class="rt-logo" style="margin-right: 5px"/>
    </template>
    <template>
      <div style="text-align: left">
        <p class="sub-title">Elastic Search</p>
        <div class="sub-title cluster-status" :class="clusterStatus.label">
          Status: {{ clusterStatus.label }}
        </div>
        <div style="display: flex; text-align: center;">
          <div style="width: 50%;height: 200px;margin-right: 10px">
            <p>CPU</p>
            <cpu-status-chart :labels="cpuUtilizationData.labels"
                              :data="cpuUtilizationData.data" />
          </div>
          <div style="width: 50%; height: 200px;margin-right: 10px">
            <p>JVM</p>
            <jvm-pressure-chart :labels="jvmPressureData.labels"
                                :data="jvmPressureData.data"/>
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FailStick from '@/views/FailPanel/components/FailStick.vue';
import Card from '@/components/Cards/Card.vue';
import LineChart from '@/components/Charts/LineChart';
import JvmPressureChart from '@/views/FailPanel/components/JvmPressureChart.vue';
import CpuStatusChart from '@/views/FailPanel/components/CpuStatusChart.vue';

@Component({
  components: {
    CpuStatusChart,
    JvmPressureChart,
    FailStick,
    Card,
    LineChart,
  },
})
export default class RealtimePanel extends Vue {
  cpuUtilizationData = {}

  jvmPressureData = {}

  clusterStatus = {}

  async beforeMount() {
    await this.refreshData();
    this.startHook();
  }

  async refreshData() {
    await this.$store.dispatch('getElasticSearchFailData').then((res) => {
      [this.cpuUtilizationData, this.jvmPressureData, this.clusterStatus] = res;
      console.log(this.clusterStatus);
    }).catch(() => {
      this.isOnError = true;
    });
  }

  startHook() {
    this.webHook = setInterval(async () => {
      await this.refreshData();
    }, 120000);
  }
}
</script>

<style lang="scss">

.aws-panel{
  min-height: 44.5vh;

  .rt-logo {
    background-image: url("~@/assets/img/aws-icon.png");
    width: 80px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%
  }

  .sub-title{
    font-size: 18px;
    font-weight: bold;
  }

  .aws-header {
    padding: 10px !important;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .cluster-status{
    width: 100%;
    height: 30px;
    background-color: rgb(45,157,72);
    text-align: center;
    color: white;
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 35px;

    &.red{
      background-color: #D61515;
    }

    &.yellow{
      background-color: #F9C109;
    }
  }
}
</style>
