














import { Component, Vue } from 'vue-property-decorator';
import RealtimePanel from '@/views/FailPanel/RealtimePanel.vue';
import SiaPanel from '@/views/FailPanel/SiaPanel.vue';
import AwsPanel from '@/views/FailPanel/AwsPanel.vue';

@Component({
  components: {
    AwsPanel,
    SiaPanel,
    RealtimePanel,
  },
})
export default class Home extends Vue {}
