<template>
  <card class="sia-panel" headerClasses="sia-header">
    <template slot="header">
      <div class="sia-logo"/>
    </template>
    <template>
      <div class="card-content">
        <fail-stick type="green" fill="bordered" v-for="market in markets" :key="market">
          <div class="stick-content">
            <div class="card-market">
              <p>{{ market }}</p>
            </div>
            <div class="card-status-container">
              <div class="card-status"></div>
              <div class="card-status"></div>
            </div>
          </div>
        </fail-stick>
      </div>
    </template>
  </card>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FailStick from '@/views/FailPanel/components/FailStick.vue';
import Card from '@/components/Cards/Card.vue';

@Component({
  components: {
    FailStick,
    Card,
  },
})
export default class SiaPanel extends Vue {
  markets = [
    'PNT',
    'PNC',
    'GRJ',
    'GSP',
    'GCA',
    'CAM',
    'BHZ',
    'GVI',
    'POA',
    'FLO',
    'CTA',
    'DFE',
    'GOI',
    'MAN',
    'GBE',
    'REC',
    'FOR',
    'SAL',
  ]
}
</script>

<style lang="scss">
.sia-panel{
  min-height: 42vh;

  .sia-logo {
    background-image: url("~@/assets/img/logo_sia.png");
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 5px
  }

  .title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px!important;
  }

  .sub-title{
    font-size: 18px;
    font-weight: bold;
  }

  .sia-header {
    padding: 10px !important;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .card-content{
    display: flex;flex-wrap: wrap;
  }

  .stick-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .card-market{
      background-color: #2D9D48;
      width: 100%
    }

    .card-status-container{
      display: flex;
      height: 100%;
      align-items: center;

      .card-status{
        background-color: #2D9D48;
        width: 37px;
        height: 35px;
        margin: 5px
      }
    }

    p{
      margin-bottom: 0!important;
    }
  }
}

</style>
