




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FailStick from '@/views/FailPanel/components/FailStick.vue';
import Card from '@/components/Cards/Card.vue';
import { RealtimeServiceStatus } from '@/data/dto/realtime-service-status';

@Component({
  components: {
    FailStick,
    Card,
  },
})
export default class RealtimePanel extends Vue {
  realtimeFailData: RealtimeServiceStatus[] = []

  simulcastFailData: RealtimeServiceStatus[] = []

  isOnError = false

  webHook: ReturnType<typeof setTimeout> | undefined

  getStickTypeByStatus(status: boolean) {
    return status ? 'success' : 'danger';
  }

  async beforeMount() {
    await this.refreshData();
    this.startHook();
  }

  beforeDestroy() {
    if (this.webHook) {
      clearInterval(this.webHook);
    }
  }

  async refreshData() {
    await Promise.all([
      this.$store.dispatch('getRealtimeAudienceFailData'),
      this.$store.dispatch('getRealtimeSimulcastFailData'),
    ]).then((res) => {
      [this.realtimeFailData, this.simulcastFailData] = res;
    }).catch(() => {
      this.isOnError = true;
    });
  }

  startHook() {
    this.webHook = setInterval(async () => {
      await this.refreshData();
    }, 120000);
  }
}
