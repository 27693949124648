<template>
  <line-chart style="height: 90%;"
              chart-id="green-line-chart"
              :chart-data="chart.chartData"
              :gradient-stops="chart.gradientStops"
              :extra-options="chart.extraOptions">
  </line-chart>
</template>

<script>
import Vue from 'vue';
import LineChart from '@/components/Charts/LineChart';
import { colors, greenChartOptions } from '@/components/Charts/config';

export default Vue.extend({
  components: {
    LineChart,
  },
  props: {
    labels: {
      type: Array,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      chart: {},
    };
  },
  watch: {
    $props: {
      handler() {
        this.chart = {
          extraOptions: greenChartOptions,
          chartData: {
            labels: this.labels,
            datasets: [{
              label: 'Utilização CPU',
              fill: true,
              borderColor: colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.data,
            }],
          },
          gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
          gradientStops: [1, 0.4, 0],
        };
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss">
</style>
