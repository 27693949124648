// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IColors {
  default: string;
  primary: string;
  info: string;
  danger: string;
  teal: string;
  primaryGradient: string[];
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IBasicOptions {
  maintainAspectRatio: boolean;
  legend: {
    display: boolean;
  };
  responsive: boolean;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IBlueChartOptions extends IBasicOptions {
  tooltips: any;
  scales: any;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IPurpleChartOptions extends IBasicOptions {
  tooltips: any;
  scales: any;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IOrangeChartOptions extends IBasicOptions {
  tooltips: any;
  scales: any;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IGreenChartOptions extends IBasicOptions {
  tooltips: any;
  scales: any;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IBarChartOptions extends IBasicOptions {
  tooltips: any;
  scales: any;
}

export const colors: IColors = {
  default: '#344675',
  primary: '#42b883',
  info: '#1d8cf8',
  danger: '#fd5d93',
  teal: '#00d6b4',
  primaryGradient: ['rgba(76, 211, 150, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)'],
};

export const basicOptions: IBasicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  responsive: true,
};

export const blueChartOptions: IBlueChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#2380f7',
      },
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#2380f7',
      },
    }],
  },
};

export const purpleChartOptions: IPurpleChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#9a9a9a',
      },
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(225,78,202,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#9a9a9a',
      },
    }],
  },
};

export const orangeChartOptions: IOrangeChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {

        padding: 20,
        fontColor: '#ff8a76',
      },
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(220,53,69,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#ff8a76',
      },
    }],
  },

};

export const greenChartOptions: IGreenChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        // suggestedMin: 0,
        // suggestedMax: 100,
        padding: 20,
        fontColor: '#9e9e9e',
      },
    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(0,242,195,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#9e9e9e',
      },
    }],
  },
};

export const barChartOptions: IBarChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  },
  scales: {
    yAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 120,
        padding: 20,
        fontColor: '#9e9e9e',
      },
    }],
    xAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        padding: 20,
        fontColor: '#9e9e9e',
      },
    }],
  },
};
